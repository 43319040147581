import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
// import pic4 from '../assets/images/pic04.jpg';
// import pic5 from '../assets/images/pic05.jpg';
// import pic6 from '../assets/images/pic06.jpg';
// import pic7 from '../assets/images/pic07.jpg';

import config from '../../config';
const IndexPage = ({ heading = null }) => (
  <Layout>
    <section id="banner">
      <div className="inner">
        {/* <div className="logo">
          <span className="icon fa-circle-thin"></span>
          <span className="icon fa-circle-thin"></span>
        </div> */}
        <h2>{heading || config.heading}</h2>
        <p style={{ marginTop: "4em", marginBottom: 0, textDecoration: 'line-through' }}>29 de Agosto, 2020</p>
        <p style={{ marginTop: 0, marginBottom: 0, textDecoration: 'line-through' }}>24 de Abril, 2021</p>
        <p>{config.subHeading}</p>
        <p>Les esperamos para la ceremonia a las 12hs (¡al mediodía!)</p>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">En El Muelle Eventos &amp; Restó</h2>
            <p>
              Club de Pescadores, Av. Costanera y Av. Sarmiento, CABA.
            </p>
            <a href="https://goo.gl/maps/a29SnebmpVHydesHA" className="special">
              Mapa
            </a>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Dress code</h2>
            <p>
              El evento es de tipo cocktail.<br />
              No hace falta corbata ni vestido largo, pero no es de jeans.
            </p>
            <p>
              ¡Importante! No olvidar zapatos para bailar, el piso es de madera.
            </p>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
